// Typography
.h1 {
  @apply text-4xl font-extrabold leading-tight tracking-tighter;
}

.h2 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter;
}

.h3 {
  @apply text-3xl font-bold leading-tight;
}

.h4 {
  @apply text-2xl font-bold leading-snug tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

// Buttons
.btn,
.btn-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded-sm leading-snug transition duration-150 ease-in-out;
}

.btn {
  @apply px-8 py-3;
}

.btn-sm {
  @apply px-4 py-2;
}

// Forms
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-transparent border border-gray-700 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded-sm;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-purple-600;
}

.composition {
  position: relative;
  &__photo {
    width: 55%;
    box-shadow: 0 1.5rem 8rem rgba(white, 0.4);
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    transition: all 0.2s;
    outline-offset: 2rem;
    @media screen and (max-width: 768px) {
      float: left;
      position: relative;
      width: 33.3333%;
      box-shadow: 0 1.5rem 3rem rgba(white, 0.2);
    }
    &--p1 {
      left: 0;
      top: -2rem;
      @media screen and (max-width: 768px) {
        top: 1rem;
        transform: scale(1.2);
      }
    }
    &--p2 {
      right: 0;
      top: 2rem;
      @media screen and (max-width: 768px) {
        top: -1rem;
        transform: scale(1.3);
        z-index: 10;
      }
    }
    &--p3 {
      left: 20%;
      top: 10rem;
      @media screen and (max-width: 768px) {
        left: 0;
        top: 1rem;
        transform: scale(1.2);
      }
    }

    &:hover {
      transform: scale(1.05) translateY(-0.5rem) !important;
      box-shadow: 0 2.5rem 4rem rgba(white, 0.5);
      z-index: 20;
    }
  }
  &:hover &__photo:not(:hover) {
    transform: scale(0.9);
  }
}
