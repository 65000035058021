@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Inter:wght@400;500;600;700;800;900&display=fallback");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
  .rtl {
    direction: rtl;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";
